import React, { Fragment, useContext, useEffect } from "react";
import { AvailableModelsContext } from "./contexts/global/AvailableModelsContext";
import { OidcConfigListContext } from "./contexts/global/OidcConfigListContext";
import { OidcConfigurationNameContext } from "./contexts/global/OidcConfigurationNameContext";
import { OidcSecureChat } from "./OidcSecureChat";
import { initializePage } from "./utilities/initializePage";
import { Loading } from "./Loading";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import LoginPage from "./LoginPage";

export const AppContainer = () => {
  useEffect(() => {}, []);
  const availableModels = useContext(AvailableModelsContext);
  const oidcConfigList = useContext(OidcConfigListContext);
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);

  useEffect(() => {
    initializePage();
  }, []);
  if (availableModels.length === 0 || oidcConfigList.length === 0) {
    return <Loading waiting />;
  }
  let configuration = oidcConfigList.find(
    (c) => c.name === oidcConfigurationName,
  );
  return (
    <Fragment>
      <CssBaseline />
      <BrowserRouter>
        <ApmRoutes>
          <Route
            path="/"
            element={
              oidcConfigurationName !== "default" && configuration != null ? (
                <OidcSecureChat />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </ApmRoutes>
      </BrowserRouter>
    </Fragment>
  );
};
