import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import IChatOptions from "../../types/interfaces/IChatOptions";
import { getChatOptions } from "../../utilities/sendGatewayRequest";
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import {OidcConfigurationNameContext} from "../global/OidcConfigurationNameContext";

export const ChatOptionsContext = createContext<IChatOptions>(
    {
        default_system_prompt: "",
        help_text: "",
        show_rag: false,
        group: [],
    },
);

export const ChatOptionsContextProvider = ({ children, }: { children: ReactNode; }) => {
    const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
    const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
    const [chatOptions, setChatOptions] = useState<IChatOptions>(
        {
            default_system_prompt: "",
            help_text: "",
            show_rag: false,
            group: [],
        },
    );
    useEffect(() => {
        (async () => {
            const chatOptionsResponse = await getChatOptions(oidcAccessToken);
            setChatOptions(chatOptionsResponse);
        })();
    }, []);

    return (
        <ChatOptionsContext.Provider value={chatOptions}>
            {children}
        </ChatOptionsContext.Provider>
    );
};
