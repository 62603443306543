import {Dialog, Link} from "@mui/material";
import {useContext} from "react";
import {ChatOptionsContext} from "../contexts/chat/ChatOptionsContext";
import IChatOptions from "../types/interfaces/IChatOptions";
import remarkGfm from "remark-gfm";
import CodeBlock from "./CodeBlock";
import ChatImage from "./ChatImage";
import ReactMarkdown from "react-markdown";

export type HelpDialogProps = {
    open: boolean,
    onClose: () => void;
}

export default function HelpDialog({open, onClose}: HelpDialogProps) {
    const chatOptions = useContext<IChatOptions>(ChatOptionsContext);
    return (<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <div style={{padding: "2em", margin: 0}}>
            <h3 style={{marginTop: 0}}>Help</h3>

            <ReactMarkdown
                className="markdown"
                remarkPlugins={[remarkGfm]}
                components={{
                    // @ts-ignore
                    pre: CodeBlock,
                    img: ChatImage,
                    a: ({ ...props }) => (
                        <a {...props} target="_blank" rel="noopener noreferrer">
                            {props.children}
                        </a>
                    ),
                }}
            >
                {chatOptions.help_text}
            </ReactMarkdown>
        </div>

    </Dialog>)
}