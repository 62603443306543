import {ChangeEvent, useContext, useState} from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import getDefaults from "../utilities/defaults";
import {ChatOptionsContext} from "../contexts/chat/ChatOptionsContext";
import IChatOptions from "../types/interfaces/IChatOptions";

export type GlobalSettingsProps = {
  open: boolean;
  currentDefaultSystemPrompt: string;
  currentUseRagByDefault: boolean;
  onSave: (newDefaultSystemPrompt: string, newUseRagByDefault: boolean) => void;
  onClose: () => void;
};

export default function GlobalSettings({
  open,
  currentDefaultSystemPrompt,
  currentUseRagByDefault,
  onSave,
  onClose,
}: GlobalSettingsProps) {
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useState<string>(
    currentDefaultSystemPrompt,
  );
  const [useRagByDefault, setUseRagByDefault] = useState<boolean>(
    currentUseRagByDefault,
  );
  const chatOptions = useContext<IChatOptions>(ChatOptionsContext);
  const showRag = chatOptions.show_rag;

  const handleClose = () => {
    setDefaultSystemPrompt(currentDefaultSystemPrompt);
    onClose();
  };

  const handleSave = () => {
    onSave(defaultSystemPrompt, useRagByDefault);
    onClose();
  };

  const handleDefaultSystemPromptChange = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    setDefaultSystemPrompt(event.target.value as string);
  };

  const handleUseRagByDefaultChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setUseRagByDefault(event.target.checked);
  };

  const handleResetPrompt = () => {
    setDefaultSystemPrompt(chatOptions.default_system_prompt);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <Card sx={{ padding: "1em", width: "100%" }}>
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>
            These changes will only be applied to newly created conversations.
          </Typography>
        </Box>
        <br />
        <TextField
          sx={{ width: "100%" }}
          multiline={true}
          maxRows={10}
          value={defaultSystemPrompt}
          label={"Default System Prompt"}
          onChange={handleDefaultSystemPromptChange}
        />
        <Button onClick={handleResetPrompt}>Reset</Button>
        <br />
        {showRag ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleUseRagByDefaultChange}
                name={"RAG"}
                checked={useRagByDefault}
              />
            }
            label={"Use stored documents to inform responses (RAG) by default"}
          />
        ) : (
          <></>
        )}
        <Button onClick={() => {}}>Delete all Conversations</Button>
        <Box display="flex" sx={{ mt: "1em" }}>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Box>
      </Card>
    </Dialog>
  );
}
