import { Alert, Box, CircularProgress, CssBaseline } from "@mui/material";
import React, { useContext } from "react";
import { DarkModeContext } from "./contexts/global/DarkModeContext";
import { AvailableModelsContext } from "./contexts/global/AvailableModelsContext";
import { OidcConfigListContext } from "./contexts/global/OidcConfigListContext";

export const Loading = ({ waiting = false }) => {
  const { darkModeEnabled } = useContext(DarkModeContext);
  const availableModels = useContext(AvailableModelsContext);
  const oidcConfigList = useContext(OidcConfigListContext);
  return (
    <html style={{ backgroundColor: darkModeEnabled ? "white" : "black" }}>
      <body>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CssBaseline />
          {waiting && availableModels.length === 0 ? (
            <Alert severity="info">
              Waiting for AI models from LLMGateway...
            </Alert>
          ) : (
            <></>
          )}
          {waiting && oidcConfigList.length === 0 ? (
            <Alert severity="info">
              Waiting for OIDC configurations from LLMGateway...
            </Alert>
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              placeItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      </body>
    </html>
  );
};
