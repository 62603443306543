import React, { useContext } from "react";
import { OidcConfigurationNameContext } from "./contexts/global/OidcConfigurationNameContext";
import { OidcSecure } from "@axa-fr/react-oidc";
import Chat from "./Chat";
import {LoadingSettingsWrapper} from "./LoadingSettingsWrapper";
import {ChatOptionsContextProvider} from "./contexts/chat/ChatOptionsContext";

export const OidcSecureChat = () => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  return (
    <OidcSecure configurationName={oidcConfigurationName}>
        <ChatOptionsContextProvider>
            <LoadingSettingsWrapper>
            <Chat />
            </LoadingSettingsWrapper>
        </ChatOptionsContextProvider>
    </OidcSecure>
  );
};
