import {ChatOptionsContext} from "./contexts/chat/ChatOptionsContext";
import IChatOptions from "./types/interfaces/IChatOptions";
import { Alert, Box, CircularProgress, CssBaseline } from "@mui/material";
import React, {ReactNode, useContext} from "react";
import { DarkModeContext } from "./contexts/global/DarkModeContext";


export const LoadingSettingsWrapper = ({children}: {children: ReactNode}) => {
    const chatOptions = useContext<IChatOptions>(ChatOptionsContext);
    const {darkModeEnabled} = useContext(DarkModeContext);
    let waiting = chatOptions.default_system_prompt === "";
    if (!waiting) {
        return (<>{children}</>);
    }
    return (
        <html style={{ backgroundColor: darkModeEnabled ? "white" : "black" }}>
        <body>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CssBaseline />
            {waiting && chatOptions.default_system_prompt === "" ? (
                <Alert severity="info">
                    Waiting for chat settings from LLMGateway...
                </Alert>
            ) : (
                <></>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    placeItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "50vh",
                }}
            >
                <CircularProgress />
            </Box>
        </Box>
        </body>
        </html>
    );
};
